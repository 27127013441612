* {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
  font-size: calc(0.75em + 1vmin);
}

body {
  background-color: rgba(51, 51, 51, 0.9);
}

.app {
  padding: 35px;
}

h1 {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 1.8rem;
}
